<template>
  <div ref="parent">
    <a-modal
      :width="655"
      centered
      :title="title"
      @cancel="closeApprovaModel"
      @ok="selectApprovalFn"
      :visible="visible"
      :getContainer="() => $refs.parent"
    >
      <a-row class="approval-modal">
        <a-col :span="12" style="padding: 16px 0 16px 14px; border-right: 1px solid rgba(0, 0, 0, 0.06)">
          <section style="margin-bottom: 24px">
            <a-input
              class="search-input"
              style="width: 190px; margin-right: 17px"
              v-model:value="check.searchLbwVal"
              :maxlength="10"
              placeholder="请输入姓名查询"
            >
              <template #prefix> <img src="@/assets/svg/search.svg" /> </template>
            </a-input>
            <a-button type="primary" @click="searchApprovalList" style="border-radius: 2px">查找</a-button>
          </section>
          <div style="overflow-y: scroll" v-show="check.searchLbwList.length">
            <a-checkbox-group :value="check.checkedList" style="width:100%">
              <Virtual :list="check.searchLbwList" :size="62" :remain="8" :isScrollTop="isVirtualListScroll">
                <template #default="{ item, index }">
                  <StaffItem :item="item" :index="index" :onChange="onChange" />
                </template>
              </Virtual>
            </a-checkbox-group>
          </div>
          <div class="no-data" v-show="!check.searchLbwList.length">
            <a-empty :image="simpleImage" class="no-icon" />
          </div>
        </a-col>
        <!-- 右侧已选 -->
        <a-col :span="12" style="padding: 16px 0 16px 14px">
          <p style="color: rgba(0, 0, 0, 0.85); margin-bottom: 24px; font-size: 14px">已选人员</p>
          <div class="scroll">
            <StaffList :list="check.checkedListRight" :onChangeList="onChangeList" />
          </div>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, watch, ref } from 'vue'
import StaffItem from '@/views/businessManage/process/components/staffItem'
import StaffList from '@/views/businessManage/process/components/staffList'
import { useCheckStateAndEvent } from '@/utils/hooks1'
import Virtual from '@/components/VirtualList/virtualList'
import { Empty } from 'ant-design-vue';
export default defineComponent({
  components: {
    StaffItem,
    StaffList,
    Virtual,
    Empty
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 左侧所有数据
    list: {
      type: Array,
      default: []
    },
    // 已选中数据
    selectList: {
      type: Array,
      default: []
    },
    // 左上侧title
    title:{
      type: String,
      default: '选择特权用印人'
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const state = reactive({
      isVirtualListScroll: 0,
      isVirtualListScroll2: 0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE
    })
    const enhancerMapFn = {
      closeApprovaModel() {
        emit('update:visible', false)
      },
      selectApprovalFn() {
        emit('update:visible', false)
        setTimeout(() => {
          state.isVirtualListScroll2 += '1'
        })
      },
      deleteApprovalFn() {
        state.isVirtualListScroll2 = {}
      },
      searchApprovalList() {
        typeof state.isVirtualListScroll === 'number' ? state.isVirtualListScroll++ : (state.isVirtualListScroll = 0)
      },
      changeStaffList() {
        // console.log('组件', check.staffList)
        check.staffList = check.staffList.filter(val => val != undefined)
        emit('selectedStaffFn', check.staffList)
      }
    }
    const {
      check,
      onChange,
      reloadSearchLbwList,
      closeApprovaModel,
      selectApprovalFn,
      searchApprovalList,
      deleteApprovalFn,
      initialApproalList,
      onChangeList
    } = useCheckStateAndEvent(enhancerMapFn)

    watch(
      () => props.visible,
      newVisible => {
        if (newVisible) {
          check.checkType = 1
          let newCheckStaffList = props.selectList.map(item => item)
          check.searchLbwList = props.list.map(item => ({ ...item, label: item.name, value: item.id }))
          check.totalSearchList = props.list.map(item => ({ ...item, label: item.name, value: item.id }))
          // console.log(newCheckStaffList,'nimade');
          initialApproalList(newCheckStaffList)
          setTimeout(() => {
            typeof state.isVirtualListScroll === 'number'
              ? state.isVirtualListScroll++
              : (state.isVirtualListScroll = 0)
          })
        }
      }
    )

    return {
      ...toRefs(state),
      check,
      selectApprovalFn,
      deleteApprovalFn,
      onChange, // 选择审批人change事件
      searchApprovalList,
      closeApprovaModel,
      reloadSearchLbwList,
      onChangeList,
      initialApproalList
    }
  }
})
</script>
<style lang="scss" scoped>
:deep(.ant-modal-header) {
  border-bottom: 0;
}
.approval-modal {
  margin-top: -24px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.no-data {
  height: 355px;
  .no-icon {
    height: 355px;
    transform: translateY(36%);
  }
}
.scroll {
  overflow-y: scroll;
  height: 376px;
  &::-webkit-scrollbar {
    display: block;
    border-radius: 4px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    width: 8px;
    background: #dddddd;
    border-radius: 4px;
  }
}
</style>
